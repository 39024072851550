import React from 'react'
import Layout from '../layout/layout'
import SEO from "../layout/seo"
import './styles/past-events.scss'
import PastEvent from '../components/PastEvent'

const schedule = () => {
    
    return (
       
        <Layout attr={"past-events-page page"}>
            <SEO title="Past Ignite Summit Events" description="Previous Ignite Summit events. Watch Apache Ignite Summit 2021 sessions on-demand." />
            <div className="container past-events-wrapper" >
                <PastEvent name="June 2023" url="/2023-june" className="left june2023" />
                <PastEvent name="November 2022" url="/2022-november" className="right november" />
                <PastEvent name="June 2022" url="/2022-june" className="left june" />
                <PastEvent name="Cloud Edition, 2021" url="/cloud-edition-2021" className="right" />
                <PastEvent name="May 2021" url="/2021" className="left" />
            </div>
        </Layout>
        
    )
}

export default schedule