import React from 'react'
import './styles/pastEvent.scss'
import igniteIcon from "../images/ignite-summit-icon.svg"


const PastEvent = ({ name, url, className }) => {

    
    return (
        <div className={`past-event ${className}`}>
            <img src={igniteIcon} width={`40px`} height={`28px`}  />
            <div className="past-event__content">
                <h2>Ignite Summit <span>{ name }</span></h2>
                <a href={ url } className="button past external white" target="_blank">Review agenda</a>
            </div>
        </div>
    )
}


export default PastEvent
